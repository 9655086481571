.banner {
    margin-top: 0;

    .banner-item {
        display: block;
        justify-content: center;
        padding: 0;

        .img-fluid {
            width: 100%;
            height: auto;
        }
    }
    .btn {
        margin-top: 1em;
        text-transform: uppercase;
    }
    .btn-primary:hover {
        background-color: transparent;
    }
    .banner-caption {
        padding: 2rem;
    }
    .banner-caption__title h2 {
        font-family: $font-family-abril;
        color: black;
        font-size: 28px;
        letter-spacing: 2px;
    }
    .banner-caption__title h3 {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 15px;
        line-height: 28px;
        color: black;
        letter-spacing: 4px;
    }

}

.banner__portrait .banner__cta {
    display: flex;
    align-items: center;
    justify-content: center;
}
