.mainmenu {
    background-color: #000;
    color: #fff;
    height: 100px;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    font-size: $font-size-sm;
    text-indent: 15px;
    font-weight: 300;
    a {
        color: #fff;
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;
    }
    a:hover {
        /*font-weight: 500;*/
        border-bottom: 2px solid;
    }
    a.navbar-brand:hover {
        border-color: transparent;
    }
    .navbar-nav {
        align-items: center;
        flex-direction: row;
        .nav-link {
            display: inline;
            text-align: center;
            padding-right: .25rem;
            padding-left: .25rem;
        }
    }
    .logo-only {
        fill: #fff;
        height: 60px;
        width: 60px;
        margin-right: 20px;
    }
    .logo-text {
        fill: #fff;
        height: 50px;
        width: 210px;
    }
    .burger {
        fill: #fff;
        height: 45px;
        width: 45px;
    }
}

@include media-breakpoint-down(sm) {
    .ui-nav-main .logo-text {
        width: 170px;
    }
}

.mainmenu.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 80px;
    transition: all 0.4s;
}

@include media-breakpoint-down(sm) {
    .mainmenu.sticky {
        height: 50px;
        padding-top: 5px;
    }
    .navbar-nav li {
        align-items: center;
        &:nth-child(2) {
            width: 30px;
        }
        &:nth-child(1) {
            width: 120px;
        }
        &:nth-child(3) {
            // width: 190px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mainmenu {
        text-indent: 5px;
    }
    .navbar-nav {
        align-items: center;
    }
    .mainmenu .logo-only {
        fill: #fff;
        height: 50px;
        width: 50px;
        margin-right: 20px;
    }
    .burger {
        fill: #fff;
        height: 45px;
        width: 45px;
    }
    .ui-nav {
        height: 64px;
        letter-spacing: 2px;
        font-family: $font-family-montserrat;
        text-transform: uppercase;
        font-size: $font-size-sm;
        text-indent: 15px;
        color: #000;
        display: none;
        a {
            color: #000;
        }
        .logo-text {
            fill: #000;
            max-width: 100%;
        }
    }
    .ui-nav.open {
        display: block;
    }
    .logo {
        text-align: center;
    }
    .logo-text {
        height: 60px;
    }
    .ui-nav-wrapper {
        background-color: $almond;
    }
    .ui-nav-wrapper.fixed {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 90px;
        max-width: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px 0px 10px -4px #222;
        z-index: 10;
    }
    ,
    .ui-nav-wrapper .nav-main {
        margin: 0;
        width: 100%;
        padding-left: 0;
    }
    .ui-nav-wrapper .nav-main li {
        display: block;
        text-align: left;
        margin: 0;
    }
    ,
    .ui-nav-wrapper .nav-main > li > a {
        border-bottom: 1px solid #000;
        display: block;
        background: $almond;
        color: #000;
        margin: 0;
        padding: 20px;
    }
    .ui-nav-wrapper .nav-main > li.user-actions > a {
        border-bottom: none;
        display: block;
        background: #fff;
        color: #000;
        margin: 0;
        padding: 10px 0;
    }
    .ui-nav-wrapper .nav-main > li > a.logo {
        padding: 20px 10px 10px;
        border-bottom: none;
    }
    a.ua-item {
        display: inline-block;
        width: 50%;
        float: left;
        text-align: center;
        position: relative;
    }
    a.ua-item:first-child:after {
        content: "|";
        display: block;
        position: absolute;
        z-index: 10;
        top: 10px;
        right: -10px;
    }
    .ui-nav-wrapper .secondary-menu {
        padding: 0;
        display: none;
        background-color: #000;
    }
    .ui-nav-wrapper .secondary-menu.open {
        display: block
    }
    .ui-nav-wrapper .secondary-menu li a {
        padding: 20px 20px 20px 40px;
        text-transform: uppercase;
        display: block;
        color: #fff;
        border-bottom: 1px solid;
    }
    .ui-nav-wrapper .secondary-menu li:last-child a {}
    .order-xs-first {
        order: 0;
    }
    .order-xs-last {
        order: 1;
    }
    .ui-btn-trial {
        float: none!important;
        display: block;
        background: #fff;
        margin: 20px;
    }
    .close-menu {
        cursor: pointer;
        height: 40px;
        outline: none;
        float: right;
    }
    .close {
        width: 20px;
        height: 20px;
        outline: none;
        margin-top: 18px;
        margin-right: 15px;
    }
}

.mainmenu--md {
    ul.navbar-nav {
        display: table;
        width: 100%;
    }
    ul.navbar-nav li {
        display: table-cell;
        width: 33.33%;
        vertical-align: middle;
    }
    ul.navbar-nav li.nav-item.nav-logo {
        text-align: center;
    }
    ul.navbar-nav li.nav-item.nav-logo-text {
        text-align: right;
    }
}

@include media-breakpoint-down(sm) {
    .mainmenu {
        height: 70px;
        .navbar-nav {
            padding-top: 7px;
        }
        .navbar-nav li {
            align-items: center;
            &:nth-child(2) {
                width: 50px;
                .logo-only {
                    width: 40px;
                    height: 40px;
                }
            }
            &:nth-child(1) {
                width: 120px;
                .burger {
                    height: 35px;
                    width: 35px;
                }
            }
            &:nth-child(3) {
                width: auto;
                margin-left: 5px;
                .logo-text {
                    height: 20px;
                    width: 205px;
                    margin-right: 5px;
                }
            }
            a.navbar-brand {
                display: none;
            }
        }
    }
}
