.megamenu {
    display: none;
    background-color: #fff;
    color: #000;
    height: 0;
    .navbar-nav:first-child {
        margin-left: 11%;
    }
    .container {
        display: flex;
        flex-direction: row;
        ul {
            flex-direction: column;
            align-items: baseline;
            padding-top: 20px;
            li {
                align-self: flex-start;
                h3 {
                    font-family: $font-family-montserrat;
                    text-transform: uppercase;
                    font-size: $font-size-sm;
                }
                a {
                    font-size: $font-size-sm;
                    text-transform: uppercase;
                    text-indent: 5px;
                    color: #000;
                    padding: 0.25em 0.5em;
                    border-bottom: 1px solid #fff;
                    &:hover {
                        border-bottom: 1px solid;
                    }
                }
                .megamenu-promo {
                    flex-direction: row;
                    display: flex;
                    padding-top: 15px;
                    padding-left: 0;
                    list-style-type: none;
                    svg {
                        width: 40px;
                        height: 40px;
                        fill: $white;
                        margin-right: 15px;
                    }
                }
            }
        }
        .megamenu-promo {
            flex-direction: row;
            display: flex;
            padding-top: 15px;
            padding-left: 0;
            list-style-type: none;
            li {
                height: 150px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            ul {
                list-style-type: none;
                svg {
                    width: 30px;
                    height: 30px;
                    margin-right: 15px;
                }
                li {
                    height: 3em;
                    h3 {
                        font-family: $font-family-abril;
                        text-transform: none;
                        color: $black;
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
    .logo-text {
        fill: #fff;
        height: 50px;
        width: 190px;
    }
    .burger {
        fill: #fff;
        height: 45px;
        width: 45px;
    }
}


/*.megamenu.open {*/

.level-2 {
    position: absolute;
    top: 79px;
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    z-index: 10000;
    display: none;
}

.nav-item:hover .level-2:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    margin: 0 -100%;
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 200px;
    z-index: 10;
}
.nav-item:hover .level-2:after {
    content: "";
    position: absolute;
    right: 0;
    background: #fff;
    margin: 0 -100%;
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 200px;
    z-index: 10;
}
/*.sticky .nav-item:hover .level-2:before,
.sticky .nav-item:hover .level-2 {
    top: 79px;
}*/
.nav-item:hover .level-2 {
    display: block;
}
.nav-item:hover .megamenu {
    height: 200px;
    display: block;
    transition: height 0.5s;
}

.nav-item:hover .level-2 div#top {
    position: absolute;
    width: 100%;
    top: -41px;
    background: transparent;
    z-index: 10000000000;
    height: 51px;
    left: 0;
}

.megamenu {
    @include media-breakpoint-down(xs) {
        .navbar-nav li {
            align-items: center;
            &:nth-child(2) {
                width: 30px;
            }
            &:nth-child(1) {
                width: 120px;
            }
            &:nth-child(3) {
                width: 190px;
                .logo-text {
                    height: 10px;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .navbar-nav li {
            align-items: center;
            &:nth-child(2) {
                width: 30px;
            }
            &:nth-child(1) {
                width: 120px;
            }
            &:nth-child(3) {
                width: 190px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .mainmenu {
            text-indent: 5px;
        }
        .navbar-nav {
            align-items: center;
        }
        .logo-only {
            fill: #fff;
            height: 60px;
            width: 60px;
            margin-right: 20px;
        }
        .logo-text {
            fill: #fff;
            height: 50px;
            width: 190px;
        }
        .burger {
            fill: #fff;
            height: 45px;
            width: 45px;
        }
        .ui-nav {
            height: 64px;
            letter-spacing: 2px;
            font-family: $font-family-montserrat;
            text-transform: uppercase;
            font-size: $font-size-sm;
            text-indent: 15px;
            color: #000;
            display: none;
            a {
                color: #000;
            }
            .logo-text {
                fill: #000;
                width: 250px;
            }
        }
        .ui-nav.open {
            display: block;
        }
        .logo {
            text-align: center;
        }
        .logo-text {
            height: 60px;
        }
        .ui-nav-wrapper {
            background-color: $almond;
        }
        .ui-nav-wrapper.fixed {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 90px;
            max-width: 450px;
            overflow-y: auto;
            overflow-x: hidden;
            box-shadow: 0px 0px 10px -4px #222;
        }
        ,
        .ui-nav-wrapper .nav-main {
            margin: 0;
            width: 100%;
            padding-left: 0;
        }
        .ui-nav-wrapper .nav-main li {
            display: block;
            text-align: left;
            margin: 0;
        }
        ,
        .ui-nav-wrapper .nav-main > li > a {
            border-bottom: 1px solid #000;
            display: block;
            background: $almond;
            color: #000;
            margin: 0;
            padding: 20px;
        }
        .ui-nav-wrapper .secondary-menu {
            padding: 0;
            display: none;
            background-color: #000;
        }
        .ui-nav-wrapper .secondary-menu.open {
            display: block
        }
        .ui-nav-wrapper .secondary-menu li a {
            padding: 20px 20px 20px 40px;
            text-transform: uppercase;
            display: block;
            color: #fff;
            border-bottom: 1px solid;
        }
        .ui-nav-wrapper .secondary-menu li:last-child a {}
        .order-xs-first {
            order: 0;
        }
        ,
        .order-xs-last {
            order: 1;
        }
        ,
        .ui-btn-trial {
            float: none!important;
            display: block;
            background: #fff;
            margin: 20px;
        }
        .close-menu {
            cursor: pointer;
            height: 40px;
            outline: none;
        }
        .close {
            width: 20px;
            height: 20px;
            outline: none;
            margin-top: 18px;
            margin-right: 15px;
        }
    }
    @include media-breakpoint-down(sm) {
        .ui-nav-main .logo-text {
            width: 170px;
        }
    }
}
