.instashop-stage {
    margin-bottom: 40px;
}

.instashop-teasers {
    text-align: center;
    margin: 40px 0;
}

.insta-profile {
    font-size: 23px;
    letter-spacing: 4px;
    margin-bottom: 20px;
    font-weight: 300;
}

.instashop-teasers h2 {
    font-size: 31px;
    letter-spacing: 4px;
    font-weight: 600;
    color: black;
    font-family: "abril-fatface", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    text-transform: lowercase;
}

.insta-teaser {
    /*    max-width: 350px;
    max-height: 350px;*/
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
}

.insta-teaser h3 {
    font-weight: 300;
    line-height: 23px;
    height: 23px;
    overflow: hidden;
    color: black;
    letter-spacing: 4px;
    font-size: 12px;
}

.insta-teaser .price-box {
    line-height: 23px;
    height: 23px;
    overflow: hidden;
    font-size: 14px;
}

.insta-teaser h3 a {
    color: #000;
}

.insta-teaser img {
    max-width: 100%;
    height: auto;
}

.insta-promo {
    margin-bottom: 20px;
    color: #5d5d5d;
    font-size: 16px;
    padding: 0 20px;
}

.ish-items {
    display: table-cell;
    vertical-align: middle;
    width: 33.33%;
}

.ish-items .promo {
    font-size: 23px;
    text-align: right;
    font-weight: 200;
}

.instashop-row {
    // margin: 40px -10px;
    // padding-left: 20px;
}

.instashop-left,
.instashop-right {
    width: 49.7%;
    display: inline-block;
    vertical-align: top;
}

.instashop-feed .action-bar {
    margin: 20px 0 40px;
}

.instashop-left .is-lg-wrapper {
    padding-right: 50px;
    margin-top: 50px;
    @include media-breakpoint-down(md) {
        padding-right: 0;
        margin-top: 0;
    }
}

.instashop-right .is-lg-wrapper {
    padding-left: 50px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
        padding-left: 0;
        margin-bottom: 0;
    }
}

.instashop-header {
    display: flex;
    align-items: center;
}

.instashop-left .is-sm-wrapper .col-6:last-child .is-sm-item {
    margin-left: 15px;
}

.instashop-left .is-sm-wrapper .col-6:first-child .is-sm-item {
    margin-right: 15px;
}

.instashop-right .is-sm-wrapper .col-6:first-child .is-sm-item {
    margin-right: 15px;
}

.instashop-right .is-sm-wrapper .col-6:last-child .is-sm-item {
    margin-left: 15px;
}

.instashop-header-item {
    display: flex;
    justify-content: center;
    padding: 2em 0;
    .btn {
        margin-top: 1em;
        text-transform: uppercase;
    }
}

.instashop-header-caption {
    margin-top: 30px;
}

.instashop-header-caption__title h2 {
    font-family: $font-family-abril;
    font-size: 28px;
    line-height: 50px;
    color: black;
}

.instashop-header-caption__title h3 {
    font-size: 26px;
    font-weight: 100;
    margin-bottom: 15px;
}

.instashop .quick-look {
    display: none;
    position: absolute;
    z-index: 10;
    bottom: 50%;
    width: 100%;
    text-align: center;
}

.instashop .quick-look a.btn.btn-quicklook {
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
}

.instashop .image-wrapper:hover .quick-look {
    display: block;
}

.isf-item {
    margin: 20px 0;
}

.instashop .image-wrapper {
    position: relative;
    &::after {
        content: ".";
        background: #000;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        display: block;
        opacity: 0;
    }
    &:hover::after {
        opacity: 0.6;
    }
}

@include media-breakpoint-down(md) {
    .instashop-left,
    .instashop-right {
        width: 100%;
        display: block;
    }
    .is-sm-wrapper,
    .is-lg-wrapper {
        width: 100%;
        padding: 0;
        margin: 20px 0;
    }
    .is-sm-wrapper img,
    .is-lg-wrapper img {
        padding: 0;
        margin: 0;
    }
    .instashop-header {
        display: block;
    }
    .ish-items {
        display: block;
        text-align: center;
        width: 100%;
        margin: 10px 0;
    }
    .quick-look-container {
        display: none;
    }
    .quick-look-container.open {
        display: block;
    }
    .ish-items .promo {
        text-align: center;
    }
}

.insta-teaser .quick-look {
    display: none;
    position: absolute;
    z-index: 10;
    bottom: 50%;
    width: 100%;
    text-align: center;
}

.insta-teaser .quick-look a.btn.btn-quicklook {
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    letter-spacing: 4px;
    border: 2px solid white;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
}

.insta-teaser:hover .quick-look {
    display: block;
}

@include media-breakpoint-down(sm) {
    .instashop-teasers h2 .icon {
        clear: both;
        display: block;
        margin: 10px 0;
    }
    .instashop-teasers h2 {
        font-size: 25px;
    }
}

.insta-teaser {
    position: relative;
    &::after {
        content: ".";
        background: #000;
        top: 0;
        bottom: 0px;
        left: 0;
        width: 100%;
        position: absolute;
        display: block;
        opacity: 0;
    }
    &:hover::after {
        opacity: 0.6;
    }
}
