.copyright {
    background-color: $black;
    height: 60px;
    color: $white;
    text-transform: uppercase;
    font-family: $font-family-montserrat;
    font-weight: 500;
    font-size: $font-size-sm;

    ul {
        flex-direction: row;
        display: flex;
        list-style-type: none;
        align-items: center;
        margin: 0;
        padding: 1em 0 0 0;
        align-content: center;
        li {
            margin: auto;
            align-self: center;
            ul {
                margin: -1em 0 0 0;
                padding: 0;
                li {
                    display: inline-flex;
                    flex-direction: row;
                    align-self: center;
                    a {
                        margin-right: 10px;
                        color: $white;
                        font-size: 12px;
                        &:hover {
                            color: $white;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        li:first-child,
        li:last-child {
            margin: 0;
        }
    }
}


@include media-breakpoint-down(md) {
    .copyright {
        display: none;
    }
}
