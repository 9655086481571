.user {
    margin-bottom: 40px;
    h1 {
        margin: 30px 0;
    }
    a.lost-pass-link {
        font-size: 12px;
        color: $black;
        display: block;
        margin: 10px 0;
    }
    .registered-users {
        margin-bottom: 40px;
    }
    .new-users {
        margin-bottom: 40px;
        @include media-breakpoint-up(md) {
            border-left: 1px solid #f1f1f1;
            padding-left: 40px;
        }
    }
    h2.legend {
        font-family: "proxima-nova";
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: 600;
        border-bottom: 2px solid;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .buttons {
        margin: 20px 0 40px;
        width: 100%;
        border-top: 1px solid #f1f1f1;
        padding-top: 20px;
    }
}

.checkout {
        margin-bottom: 40px;
    h1 {
        margin: 30px 0;
    }
}
