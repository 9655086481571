.category-teaser-wrapper {
    padding: 1rem 0;
    background: #e7e7e7;
    margin-bottom: 40px;
}

.category-teasers {
    margin-top: 40px;
    margin-bottom: 20px;

    .icon {
        display: inline-block;
        vertical-align: top;
        padding: 5px 10px 0 0;
    }
    .category-caption__title {
        display: inline-block;
    }
    .category-caption__title h2 {
        font-family: $font-family-abril;
        color: black;
        font-size: 28px;
        letter-spacing: 2px;
    }
    .category-caption__title h3 {
        font-size: 26px;
        color: black;
        font-weight: 300;
        margin-bottom: 15px;
        letter-spacing: 4px;
    }
    .btn {
      display: block;
    }
    .btn-primary:hover {
        background-color: transparent;
    }
}

.teasers-carousel > h3 {
  font-weight: 300;
  line-height: 23px;
  height: 23px;
  overflow: hidden;
  color: black;
  letter-spacing: 4px;
  margin-bottom: 20px;
  font-size: 24px;
}

.category-image img {
    max-width: 100%;
    height: auto;
}

.category-caption {
    margin: 40px 0;
}

@include media-breakpoint-up(md) {
    .category-teaser-left {
        margin-right: 25px;
    }
    .category-teaser-right {
        margin-left: 25px;
    }
}

.category-image
{
    position: relative;
}
.category-teaser__promos {
    display: none;
  width: 100%;
  background: #EDCDC5;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.category-teaser__promos__item {
  display: table-cell;
  padding: 10px;
  width: 30%;
}
.category-teaser__promos__title {
  text-align: center;
  border-top: 1px solid;
  padding: 10px 0;
  color: #fff;
}
.category-image:hover .category-teaser__promos {
  display: table;
}
