.search-bar {
    padding: 95px 0 60px;
    position: fixed;
    background: #fff;
    z-index: 9999;
    width: 100%;
    top: -200px;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        padding: 65px 0 60px;
    }
    &.open {
        top: 0;
        transition: all 1s;
    }
    .form-search {
        display: flex;
        justify-content: space-between;
        input {
            line-height: 45px;
            border: none;
            width: 80%;
            border-bottom: 1px solid #000;
            border-radius: 0;
            outline: none;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        .btn-primary {
            min-height: 50px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 4px;
            @include media-breakpoint-down(sm) {
                min-height: 40px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 4px;
                position: absolute;
                bottom: -47px;
                right: 10px;
                width: auto;
            }
            &:hover {
                background-color: transparent;
                color: #000;
                border: 2px solid #000;
            }
        }
    }
    .close-search {
        height: 40px;
        width: 40px;
        background-color: black;
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
        svg {
            height: 20px;
            width: 20px;
            fill: #fff;
        }
    }
}
