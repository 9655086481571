.cart {
    margin-bottom: 40px;
    .value {
        color: #000;
        text-align: right;
    }
    h1 {
        margin: 30px 0 10px;
    }
    .line-items {
        display: block;
    }
    .cart-item-image {
        display: inline-block;
        width: auto;
        margin: 0 15px 15px 0px;
        vertical-align: top;
    }
    .cart-item-description {
        display: inline-block;
        width: 50%;
        @include media-breakpoint-down(sm) {
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .cart-collaterals .totals {
        margin-bottom: 10px;
        .total-amount {
            font-weight: bold;
            color: #000;
        }
        .row {
            padding: 10px;
            border-bottom: 1px solid #f1f1f1;
        }
    }
    .delivery-promise {
        margin-bottom: 30px;
        @include media-breakpoint-down(sm) {
            span {
                display: block;
            }
        }
    }
    .cart-table {
        margin: 0 0 30px;
    }

    a.update-qty {
        color: gray;
        font-size: 10px;
    }

}
