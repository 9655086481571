.orders {
    background-color: $almond;
    font-size: $font-size-sm;
    text-transform: uppercase;
    height:75px;
    letter-spacing: 3px;
    padding: 1em 0;
    color:$black;
    font-family: $font-family-montserrat;

    @include media-breakpoint-down(md)  {
        height: 60px;
        font-size: $font-size-xs;
    }

    h4 {
        font-size: $font-size-sm;

        @include media-breakpoint-down(sm)  {
            font-size: $font-size-xs;

        }
    }


    ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        justify-content: space-between;
        text-align: center;
        letter-spacing: 3px;

        p {
            letter-spacing: 2px;
        }
        @include media-breakpoint-down(md)  {
            justify-content: center;
            padding: 0;

       }
    }
}
