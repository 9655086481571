.infopage {

    h1 {
        margin: 30px 0;
    }

    p {
        letter-spacing: 0;
        margin-bottom: 15px;
    }

    .action-bar {
        margin:40px 0;
    }

}
