.newsletter {
    background-color: $almond;
    font-size: $font-size-sm;
    height: 85px;
    form#form_newsletter {
        position: relative;
    }
    @include media-breakpoint-down(md) {
        height: auto;
        a.newsletter_register svg {
            height: 20px;
            width: 20px;
            position: absolute;
            right: 20px;
            bottom: 30px;
        }
    }
    @include media-breakpoint-down(sm) {
        height: auto;
        a.newsletter_register svg {
            position: relative;
        }
    }
    color: $black;
    font-family: $font-family-montserrat;
    @include media-breakpoint-down(sm) {
        height: auto;
        font-size: 0.575rem;
        label {
            font-size: 0;
            position: relative;
            display: block;
            width: 100%;
            text-align: right;
        }
    }
    h4 {
        font-size: $font-size-lg;
        font-family: $font-family-abril;
        align-self: center;
        @include media-breakpoint-down(xs) {
            font-size: 24px;
            text-align: center;
        }
    }
    .delivery {
        padding-top: 1.2em;
        padding-bottom: 20px;
        .normal {
            margin-bottom: 10px;
            display: block;
                @include media-breakpoint-down(sm) {
                    text-align: center;
                    font-size: 16px;
                    letter-spacing: 0;
                }
        }
    }
    ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;

        h4 {
            top: 1px;
            position: relative;
        }
        .newsletter__copy {
          padding: 0 15px;
          font-size: 15px;
        }

        form.hide-submit input[type="submit"] {
            display: none;
        }
        form {
            display: inline-flex;
        }
        .form-control {
            background: transparent;
            border: none;
            border-bottom: 2px solid #000;
        }
        .arrow-right {
            width: 20px;
            height: 20px;
        }
        li {
            flex-direction: row-reverse;
            display: flex;
            align-self: baseline;
            .newsletter {
                width: 110px;
                margin-right: 20px;
            }
            a.newsletter_register {
              max-height: 85px;
            }
        }
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}
