.product-listing {
  margin-bottom: 60px;
}
.filters {
  margin-top: 80px;
  padding-left: 10px;
}
.filters a {
  color: $gray;
  font-weight: 200;
  line-height: 30px;
  font-family: $font-family-montserrat;
}
.filters a:hover {
    font-weight: 500;
}
.filter-set >h1, .filter-set >h2 {
  font-family: $font-family-abril;
  font-size: 18px;
  line-height: 50px;
  position: relative;
  color: #000;
}

.filter-set >h1:after, .filter-set >h2:after {
  content: "";
  background: $black;
  width: 40px;
  height: 4px;
  position: absolute;
  border-radius: 32px;
  bottom: 0;
  left: 0;
}

.filters ul {
  list-style: none;
  padding-left: 0;

  li ul {
      padding-left: 25px;
  }
}

.filter-set >h1 > a, .filter-set > h2 > a {
  color: $black;
  text-transform: lowercase;
  font-family: $font-family-abril;
  color: #000;
}

ul.categories li.active {
  list-style: disc;
}
ul.categories li.active > a {
  color: $black;
  font-weight: 600;
}
ul.categories h3 > a {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: #000;
}

.toolbar {
  margin-bottom: 30px;
  /*margin-top: 40px;*/
}



.sort-by select {
  min-width: 140px;
  color: #757575;
  outline: none;
  font-size: 12px;
  letter-spacing: 2px;
}

.sort-by .select {
  display: inline-block;
  color: #757575!important;
  border-color: #757575;
  padding: 2px 10px;
  }


.toolbar .pages {
  font-weight: 200;
  font-size: 12px!important;
  white-space: nowrap;
  letter-spacing: 2px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

a.btn.btn-secondary.view-all {
  font-size: 12px;
  margin-left: 20px;
  border-color: #757575;
  letter-spacing: 2px;
}

.pages-info a {
  color: #757575;
}

.pages .current {
  font-weight: bold;
}


/* product grid starts here */
.category-item.product-list-item .product-image-wrapper {
  /*max-height: 350px;*/
  overflow: hidden;
  position: relative;

}
.category-item.product-list-item {
  position: relative;
  /*max-height: 627px;*/
  overflow: hidden;
}

.category-item.product-list-item .product-sold-out {
    margin: 0 auto;
    text-align: center;
    background: #000;
    color: #fff;
    display: block;
    text-align: center;
    padding: 0px 10px;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    width: 130px;
    left: 50%;
    margin-left: -65px;
    z-index: 100;
}


.product-list-item .quick-look {
  display: none;
  position: absolute;
  z-index: 10;
  bottom: 40px;
  width: 100%;
  text-align: center;
}


a.btn.btn-quicklook {
  height: auto;
  background: rgba(255, 255, 255, 0.77);
  border: 1px solid;
  color: #000;
}

.product-list-item .wishlist {
  /*display: none;*/
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
}

.product-list-item:hover .quick-look,
.product-list-item:hover .wishlist {
  display: block;
}

.wishlist svg {
  width: 15px;
  height: 15px;
}

.wishlist.wishlist--in svg {
    fill: #000;
}

.category-item.product-list-item h3 {
  margin-bottom: 0;
}
.category-item.product-list-item .product-name a {
  color: #000;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 10px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 10px 0;
}

.category-item.product-list-item .price-box {
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-weight: bold;
  font-size:16px;
}

.product-list-item .quick-look a {
  font-size: 14px;
  line-height: 7px;
  letter-spacing: 2px;
}

.quick-look.container {
  position: relative;
}

.quick-look > .row{
  border-top: 2px solid;
  border-bottom: 2px solid;
  margin: 40px 0;
  padding: 40px 0;
}
.quick-look-container {
    max-width: 100%;
}

.quick-look__gallery img.main-image {
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}


.quick-look .cta {
  clear: both;
  display: table;
  width: 100%;
  margin-top: 20px;
}
.quick-look .cta .cta__item {
    float: left;
    margin-right: 0;
}

.quick-look__close {
  position: absolute;
  top: -20px;
  right: 0;
}

.quick-look h2 {
  font-size: 22px;
  letter-spacing: 2px;
  margin-bottom: 5px;
}
.quick-look .add-to-wishlist .btn {
    padding: 11px 10px 5px;
}

.quick-look__price {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.quick-look__variation {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 2px;
}


.cta_fullstory {
  position: absolute;
  bottom: 10px;
}

.quick-look__actions {
  max-width: 300px;
}

.quick-look .btn {
  height: 60px;
  min-width: 60px;
}

.quick-look .add-to-cart.cta__item .btn {
  width: 230px;
  margin-right: 10px;
}



.quick-look__actions .select {
  padding: 11px;
}


.quick-look .owl-prev {
  position: absolute;
  bottom: 50%;
  left: 0;
}
.quick-look .owl-next {
  position: absolute;
  bottom: 50%;
  right: 0;
}

.quick-look__close svg {
  stroke:$black;
  width: 20px;
  height: 20px;
}

.quick-look__info__wrapper {
  padding-left: 40px;
}

.product-options .select {
  max-width: 230px;
  margin-bottom: 10px;
  padding: 11px 10px;
}

.quantity.cta__item .select {
  padding: 11px 10px;
}

.product-options select.form-control:not([size]):not([multiple]) {
  padding: 0!important;
  -webkit-appearance:none;
}


ul.slides {
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
  opacity: 0;
}

.product-image-wrapper:hover  ul.slides {
  z-index: 1;
  opacity: 1;
}

@media (max-width: 900px) {
    .product-list-item .quick-look a, .quick-look  {
        display: none;
    }
}
