.footermenu {
    background-color: #000;
    color: #fff;
    height: 240px;
    @include media-breakpoint-down(md) {
        height: 130px;
    }
    .container {
        display: flex;
        flex-direction: row;
        ul {
            flex-direction: column;
            align-items: baseline;
            padding-top: 30px;
            li {
                align-self: flex-start;
                @include media-breakpoint-down(md) {
                    align-self: center;
                }
                h3 {
                    font-family: $font-family-montserrat;
                    text-transform: uppercase;
                    font-size: $font-size-sm;
                    font-weight: 500;
                    margin-bottom: 20px;
                    @include media-breakpoint-down(md) {
                        width: 160px;
                    }
                }
                a {
                    font-size: $font-size-xs;
                    color: $white;
                    font-weight: 200;
                    padding: 0.25em 0;
                    &:hover {
                        text-decoration:underline;
                    }
                }
                .social-icons {
                    flex-direction: row;
                    display: flex;
                    padding-top: 15px;
                    padding-left: 0;
                    list-style-type: none;
                    @include media-breakpoint-down(md) {
                        width: 160px;
                        padding-left: 40%;
                        li:nth-child(2) {
                            width: 120px;
                        }
                    }
                    svg {
                        width: 40px;
                        height: 40px;
                        fill: $white;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .logo-text {
        fill: #fff;
        height: 50px;
        width: 190px;
    }
    .burger {
        fill: #fff;
        height: 45px;
        width: 45px;
    }
}

.footermenu {
    @include media-breakpoint-down(xs) {
        .navbar-nav li {
            align-items: center;
            &:nth-child(2) {
                width: 30px;
            }
            &:nth-child(1) {
                width: 120px;
            }
            &:nth-child(3) {
                width: 190px;
                .logo-text {
                    height: 10px;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .navbar-nav li {
            align-items: center;
            &:nth-child(2) {
                width: 30px;
            }
            &:nth-child(1) {
                width: 120px;
            }
            &:nth-child(3) {
                width: 190px;
            }
        }
    }
}

#back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    z-index: 999;
    @include media-breakpoint-down(xs) {
        height: 30px;
        width: 30px;
    }
    svg {
        fill: black;
    }
}

.footermenu--touch {
    width: 100%;
    height: auto;
    padding-bottom: 20px;

    .callorders {
        background:#EDCDC5;
        color: #000;
        padding-bottom: 5px;
        margin: 15px 0 0;

        svg {
            fill: #000;
            max-height: 30px;
            margin-top: 10px;
        }
        h3 {
            color: #000!important;
            margin:10px 0 0;
            font-weight: bold;
        }
        .phone {
            float: left;
        }
    }

    .container ul {
        padding-top: 0;
    }

    h3 {
        width: 100%;
        text-align: center;
        margin: 20px 0 10px;
        color: #fff;
    }

    .social-menu {
        width: 100%;
    }
    svg {
        width: 50px;
        height: 50px;
        fill: $white;
        margin: 5px;
    }
}
