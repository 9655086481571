ul.nav.nav-tabs {
    display: block;
    border: none;
}

.account .nav-tabs .nav-link {
    font-size: 14px;
    color: #000!important;
    position: relative;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #eee!important;
    border: none;
    color: #000!important;
}

.account .tab-content {
    background-color: #eee;
}

.account .form-control {
    background-color: transparent;
}

.account .nav-tabs .nav-link.active:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-top: 0px solid transparent;
    border-bottom: 35px solid transparent;
    border-left: 35px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.account .user__profile--main {
    margin-bottom: 0;
    .recent-orders {
        margin: 20px 0;
    }
}

.account-info {
    background: #5D5D5D;
    color: #fff;
    padding: 10px 0 5px;
    margin-bottom: 20px;
}

.account {
    .tab-handle {
        background: #fff;
        border: 1px solid #eee;
    }
    .tab-content {
        background: #fff;
        margin: 0;
    }
    .tab-content > .active {
        background: #eee;
        padding: 10px 0;
    }
}

.account--snack-title {
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    position: relative;
}

.account--snack-edit {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;
    color: #535353;
}

.account--snack-content {
    padding: 10px;
    margin-bottom: 10px;
}

.checkout {
    color: #818181;
    .form-control:focus {
        border-color: #000;
        font-weight: bold;
    }
    .label {
        margin-bottom: 5px;
        text-align: right;
    }
    .checkout-title {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid;
        font-weight: 500;
    }
    .login {
        margin-bottom: 30px;
    }
    span.brand-name {
        font-weight: bold;
    }
    .selection-details {
        background: #eee;
        padding: 10px;
        font-size: 12px;
    }
    .line-items {
        margin-bottom: 20px;
    }
    .line-item {
        //margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
    }
    .product-title {
        font-weight: 600;
        color: #000;
    }
    .product-option {
        font-size: 14px;
        margin: 2px 0;
        color: #000;
    }
    .product-option .label {
        font-size: 13px;
        color: #818181;
    }
    .checkout-total {
        background: #eee;
        padding: 20px 10px;
        text-align: center;
        .total {
            color: #000;
        }
    }
    .checkout .select {
        height: 40px;
        padding-top: 7px;
        border-color: #ddd;
    }
}

select.form-control:not([size]):not([multiple]) {
    height: calc(1.9rem + 2px)!important;
}

.final-cta a {
    color: #fff!important;
    font-size: 14px;
    line-height: 30px;
}

.login-form {
    display: none;
}

.open .login-form {
    display: block;
    max-width: 400px;
}

.login-button .btn {
    margin-bottom: 30px;
}

@include media-breakpoint-up(md) {
    div#order-review-handle .arrow-top {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .login-button .btn {
        width: 100%;
        margin-bottom: 30px;
    }
    .checkout-review {
        //position: fixed;
        background: #eee;
        //z-index: 1;
        padding-top: 15px;
        //top: 90vh;
        //min-height: 1000px;
        //left: 0;
        //right: 0;
    }
    .line-items {
        //display: none;
    }
    .checkout .checkout-total {
        background: #fff;
        padding: 5px;
    }
    .checkout-review .checkout-title {
        margin: 0;
        border: none;
        font-size: 17px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }
    div#order-review-handle .arrow-top {
        display: none;
        width: 20px;
        height: 20px;
        float: right;
        transform: rotate(-90deg);
    }
    .checkout .checkout-review.open {
        top: 40px;
    }
    .checkout .checkout-review.open .line-items {
        display: block;
        overflow-y: scroll;
        max-height: 195px;
    }
    .nav-link .arrow-right {
        height: 20px;
        width: 20px;
        float: right;
    }
    a.nav-link {
        width: 100%;
        display: block;
    }
    .nav-link.active .arrow-right {
        transform: rotate(90deg);
    }
    .nav-link.active {
        background: #eee;
        color: #000;
        border-bottom: 1px solid #ddd;
    }
}

.checkout-costs {
    font-size: 12px;
    text-align: right;
}

.checkout-cost-item {
    padding: 0px 10px;
}

.checkout-costs .total {
    /*width: 80px;*/
    display: inline-block;
    font-weight: bold;
    text-align: center;
}
