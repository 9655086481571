.topmenu {
    background-color: #fff;
    font-size: $font-size-xs;
    text-transform: uppercase;
    height:40px;
    padding-top: 15px;
    color:$black;
    font-family: $font-family-montserrat;

    .navbar-nav {
        display: flex;
        flex-direction: row;
    }

    @include media-breakpoint-up(lg)  {
        .navbar-nav {
            flex-direction: row;
        }
    }

    .icon-search {
        height: 12px;
        width: 12px;
    }

    @include media-breakpoint-down(md)  {
        .navbar-nav {
            flex-direction: row;
            margin: 0 auto;
        }

        .navbar-nav .nav-item {
             width: 65px;
  text-align: center;
        }
        .search {
            fill: #000;
            width: 25px;
            height:25px;
        }

        .shopping-bag {
            fill: #000;
            width: 25px;
            height:25px;
        }
        .heart {
            fill: #000;
            width: 25px;
            height:25px;
        }
    }
}
