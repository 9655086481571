.creditcard {
    background-color: white;
    height: 50px;
    text-align: center;
    @include media-breakpoint-up(lg) {
        max-width: 300px;
        margin: 0 auto;
    }
    ul {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        padding-top: 0.5em;
        padding-left: 0;
        list-style-type: none;
        text-align: center;
        li {
            svg {
                height: 35px;
                margin-right: 15px;
                @include media-breakpoint-down(sm) {
                    height: 20px;
                }
            }
        }
    }
}
