.lookbook-item {
    display: flex;
    justify-content: center;
    padding: 2em 0;

    .btn {
        margin-top: 1em;
        text-transform: uppercase;
    }
}

.featuring-products .quick-look-container {
  display: none;
}


.featuring-products .action-bar {
    margin: 40px 0;
}

.featuring-products .action-bar .pager-btn {
}
