body {
    letter-spacing: 2px;
}

* {
    text-rendering: optimizelegibility;
}

a {
    color: #010101;
}

.padded {
    padding: 20px 0;
}

body.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
}

.hide {
    display: none;
}

.container-fluid {
    max-width: 2000px;
}

button,
input {
    font-family: $font-family-sans-serif;
}

.full-width {
    width: 100%!important;
}

.half-width {
    width: 50%;
    &--center {
        margin: 0 auto;
    }
    &--left {
        text-align: left;
    }
    &--right {
        text-align: right;
    }
}

.btn {
    font-size: 14px;
}

.btn-primary {
    min-height: 50px;
    font-weight: 400;
    letter-spacing: 4px;
    background-color: #000;
    color: #fff;
}

.btn-primary:hover {
    background-color: $almond;
    color: #black;
}

.btn-black {
    background-color: $black;
    color: #fff;
}

.btn-secondary:hover {
    background-color: $black;
    color: #fff;
}

.btn-secondary:hover svg {
    fill: #fff;
}

.btn-black:hover {
    background-color: transparent;
}

a:hover {
    text-decoration: none;
}

.form-inline .form-select {
    margin-right: 5px;
}

.form-select {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 6px;
}


/* Shutter Out Horizontal */

.hvr-shutter-out-horizontal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    // background: $black;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.hvr-shutter-out-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $almond;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-shutter-out-horizontal:hover,
.hvr-shutter-out-horizontal:focus,
.hvr-shutter-out-horizontal:active {
    color: $black;
}

.hvr-shutter-out-horizontal:hover:before,
.hvr-shutter-out-horizontal:focus:before,
.hvr-shutter-out-horizontal:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}


/* Pulse */

@-webkit-keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

.hvr-pulse {
    display: inline-block;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
}

input#search {
  font-size: 22px;
}


.btn:hover .hvr-pulse,
.btn:hover .hvr-pulse:focus,
.btn:hover .hvr-pulse:active {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.abril {
    font-family: $font-family-abril;
}

.font--light {
    font-weight: 300;
}

.fake-list {
    list-style: none;
    padding-left: 0;
}

h2.block-header {
    font-family: $font-family-abril;
    text-align: center;
    font-size: 28px;
    line-height: 50px;
    margin: 20px 0 30px;
    color: #000;
    letter-spacing: 2px;
}

.gutter--bottom {
    margin-bottom: 10px;
}

.gutter--top {
    margin-top: 10px;
}

.myorders {
    margin-bottom: 40px;
    h1 {
        margin: 20px 0;
    }
    .order-filters {
        margin: 20px 0;
    }
}

.myaccount {
    margin-bottom: 40px;
    h1 {
        margin: 20px 0;
    }
    .order-filters {
        margin: 20px 0;
    }
}

.page-title h1 {
    font-size: 30px;
    text-transform: uppercase;
}

.btn-300 {
    min-width: 300px;
}
