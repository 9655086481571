select {
  border: none!important;
  height: 26px!important;
  background-color: transparent;
  font-size: 14px;
  font-weight: 200;
}

.select {
    border: 1px solid $black;
    border-radius: 0;
    min-width: 40px;
}

@include media-breakpoint-down(sm) {
    select {
      height: 18px!important;
    }

    .select {
        min-width: 20px;
    }

}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #000;
  outline: none;
}
