.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  height: 50px;
  line-height: 30px;
}

li.breadcrumb-item {
  font-weight: 600;
}

li.breadcrumb-item > a {
  color: #585857;
  font-weight: 100;
}

li.breadcrumb-item {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media (max-width: 600px){
.breadcrumb {
    display: none;
}
}
