.view-more {
    margin-top: 30px;
}


.teasers-carousel .owl-carousel .owl-nav {
    .owl-prev {
        position: absolute;
        bottom: 45%;
        left: -45px;
    }
    .owl-next {
        position: absolute;
        bottom: 45%;
        right: -45px;
    }
}

@include media-breakpoint-down(sm) {
    .teasers-carousel .owl-carousel .owl-nav {
        display: none;
    }
}
/*.teasers-carousel .owl-carousel .owl-stage-outer {
    overflow: visible!important;
}

.teasers-carousel {
    max-height: 100%;
    overflow: hidden;
    margin: 60px 0;
}

.teasers-carousel .owl-item {
    opacity: 0;
}

.teasers-carousel .owl-item.active {
    opacity: 1;
    padding: 10px;
}
*/
.teasers-carousel h2,
.teasers-carousel h3 {
    text-align: center;
}

.teasers-carousel h3 {
    letter-spacing: 4px;
    font-weight: 300;
    line-height: 23px;
    height: 23px;
    overflow: hidden;
    color: black;
    letter-spacing: 4px
}

.teasers-carousel > h2 {
  font-size: 28px;
  font-family: $font-family-abril;
  color: #000;
  letter-spacing: 2px;
}

.recently-viewed .quick-look,
.teasers-carousel .quick-look {
    display: none;
    position: absolute;
    z-index: 10;
    bottom: 50%;
    width: 100%;
    margin:0 auto;
    text-align: center;
}


.recently-viewed .quick-look a.btn.btn-quicklook,
.teasers-carousel .quick-look a.btn.btn-quicklook {
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    letter-spacing: 4px;
    border: 2px solid white;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    max-width: 100%;
}

.recently-viewed .owl-item:hover .quick-look,
.teasers-carousel .owl-item:hover .quick-look {
    display: block;
}

.recently-viewed .image-wrapper,
.teasers-carousel .image-wrapper {
    position: relative;

    &::after {
        content: ".";
        background: #000;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        display: block;
        opacity: 0;
    }

    &:hover::after {
        opacity: 0.6;
    }
}
