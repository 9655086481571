.video {
    margin-top: 0;
}

.video-item {
    display: flex;
    justify-content: center;
    padding: 2em 0;

    .btn {
        margin-top: 1em;
        text-transform: uppercase;
    }
    .video-caption {
        margin-top: 30px;
    }
    .video-caption__title {
        font-family: $font-family-abril;
        color: black;
        margin: 1em 0 3em;

   }

}
