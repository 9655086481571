.stage {
    font-family: $font-family-montserrat, Helvetica;
    &.container-fluid {
        max-width: 1600px;
    }
}

.product__additional__item.open .notes {
    display: block;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 300;
}

.product__additional__item .notes {
    display: none;
}

.add-to-wishlist .heart {
    fill: #000;
    width: 20px;
    height: 20px;
}

.product__additional h3 {
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.product__additional__item .arrow-down {
    width: 12px;
    margin-left: 10px;
    height: 17px;
    vertical-align: bottom;
    transition: all 1s;
    fill: #373a3c;
}

.product__additional__item.open .arrow-down {
    transform: rotate(90deg);
}

.stage h1 {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 35px;
    color: black;
    text-transform: uppercase;
}

.stage .add-to-wishlist .btn {
    padding: 11px 20px 5px;
}

.stage .add-to-wishlist .btn svg {
    fill: #fff;
}

.stage__price {
    font-size: 26px;
    font-weight: 200;
    line-height: 50px;
    letter-spacing: 2px;
}

.stage__variation {
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.model-description {
    color: #757575;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 20px;
}

.highlights ul {
    padding: 0;
}

.highlights ul li {
    list-style: none;
    margin-bottom: 5px;
    color: black;
    font-weight: 100;
    font-size: 15px;
}

.highlights h2 {
    font-size: 17px;
    margin-bottom: 10px;
}

.highlights {
    margin: 30px 0;
    color: black;
}

.stage .cta {
    clear: both;
    display: table;
    width: 100%;
}

.stage .cta .cta__item {
    float: left;
    margin-right: 10px;
}

.social a {
    margin: 2px;
}

.social a svg {
    fill: black;
    height: 30px;
    width: 30px;
}

@include media-breakpoint-up(md) {
    .stage__gallery {
        padding-right: 60px;
    }
}

.stage__gallery .owl-prev {
    position: absolute;
    bottom: 50%;
    left: 10px;
}

.stage__gallery .owl-next {
    position: absolute;
    bottom: 50%;
    right: 10px;
}

.gallery__thumbs.owl-theme {
    display: flex;
    margin: 0 -5px;
    overflow: hidden;
}

.gallery__thumb {
    max-width: 95px;
    max-height: 97px;
    overflow: hidden;
    margin: 5px;
}

.gallery__thumbs--vertical.owl-theme {
        display: block;
        margin: 0 -5px;
        overflow: hidden;

    .gallery__thumb {
        /*max-width: auto;
        max-height: auto;
        overflow: auto;*/
        margin: 5px;
    }
}

.gallery__thumb img {
    max-width: 100%;
    height: auto;
}

.complete-the-look .owl-carousel .owl-nav {
    .owl-prev {
        position: absolute;
        bottom: 55%;
        left: 10px;
    }
    .owl-next {
        position: absolute;
        bottom: 55%;
        right: 10px;
    }
}

.complete-the-look .owl-carousel .owl-stage-outer {
    overflow: visible!important;
}

.complete-the-look {
    max-height: 100%;
    overflow: hidden;
    margin: 60px 0 0;
}

.complete-the-look .owl-item {
    opacity: 0.5;
}

.complete-the-look .owl-item.active {
    opacity: 1;
}

.owl-carousel .item h3 {
    margin-top: 10px;
}

.owl-carousel .item h3 {
    font-size: 12px;
    text-align: center;
}

.owl-carousel .item .price {
    font-size: 14px;
    text-align: center;
}

.complete-the-look h2 {
    font-family: $font-family-abril;
    text-align: center;
    letter-spacing: 4px;
    font-size: 28px;
    line-height: 50px;
    margin: 20px 0;
    letter-spacing: 1px;
    color: #000;
}

.recently-viewed .owl-carousel .owl-nav {
    .owl-prev {
        position: absolute;
        bottom: 55%;
        left: 0;
    }
    .owl-next {
        position: absolute;
        bottom: 55%;
        right: 0;
    }
}

.recently-viewed {
    max-height: 100%;
    overflow: hidden;
    margin: 60px 0;
}

.recently-viewed h2 {
    font-family: $font-family-abril;
    text-align: center;
    font-size: 28px;
    line-height: 50px;
    margin: 20px 0 30px;
    color: #000;
    letter-spacing: 1px;
}

.product-image-wrapper img {
    max-width: 100%;
    height: auto;
}

.product-options {
    position: relative;
    .select select {
        max-width: 380px;
    }
    & .size-chart-trigger {
        svg {
            height: 45px;
            width: 45px;
        }
    }
}

.stage .product-options .select {
    max-width: 380px;
}

.toolbar {
    margin-bottom: 20px;
}

.toolbar > div {
    margin-bottom: 20px;
}

.toolbar .btn {
  display: inline-block;
  font-weight: 200;
  line-height: 1.25;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 0;
}
.toolbar .btn.select:active, .toolbar .btn.select:hover {
    background-color: transparent!important;
}

.size-chart-trigger {
    position: absolute;
    right: 10px;
    top: 2px;
    cursor: pointer;
}

.size-chart {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    margin: 0 auto;
    z-index: 10000;
}

.size-chart img {
    max-width: 100%;
    height: auto;
    position: absolute;
    margin: 20px auto;
    top: 20%;
    left: 50vw;
    margin-left: -300px;
}

.size-chart:before {
    display: block;
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.67);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.owl-item img.main-image {
    width: 100%;
    height: auto;
}

.zoom-open {
    display: inline-block;
}

.zoom-close {
    display: none;
}

body.slideshow {
    .stage__gallery {
        padding: 50px 10%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 1000;
        width: 100%;
        float: none;
        max-width: 100%;
        overflow: scroll;
    }
    .zoom-open {
        display: none;
    }
    .zoom-close {
        display: inline-block;
    }
}

.complete-the-look .moreinfo.products-grid {
    text-align: center;
    text-transform: uppercase;
}

.complete-the-look .insta-teaser::after {
    content: ".";
    background: #000;
    top: 0;
    bottom: 54px;
}

.owl-item .easyzoom-flyout img {
    width: 2000px!important;
}

.minicart-tray {
  max-width: 300px;
  padding: 20px 10px;
  position: absolute;
  right: 0;
  background: #fff;
  z-index: 1000;
}

.js-close {
  text-align: right;
}
.mc-message {
    color: #585857;
}
.mc-image {
  max-width: 47%;
  float: left;
  padding-right: 10px;
  margin-bottom: 20px;
}

button.btn.btn-cart {
  font-size: 12px;
  padding: 10px;
  width: 100%;
}

.mc-product-wrapper {
  clear: both;
  display: block;
}

.mc-info {
  max-width: 45%;
  float: left;
  padding-top: 10px;
}

.mc-line {
  border: 1px solid #f1f1f1;
}

.mc-price.mc-price--total {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

@include media-breakpoint-down(sm) {
    .owl-item .easyzoom-flyout img {}
}

@include media-breakpoint-down(md) {
    .stage .product-options .select {
        max-width: 200px;
    }
    .stage .quantity.cta__item {
        display: none;
    }
    .stage .cta .cta__item {
        float: left;
        margin-right: 5px;
    }
    .size-chart img {
        max-width: 100%;
        height: auto;
        position: absolute;
        margin: 0;
        top: 20%;
        left: 0;
        margin-left: 0;
    }
}
